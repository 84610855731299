import buffer from 'buffer';
import nrv2eDecompress from 'nrv2e-decompress';

class AztecReader {
  // eslint-disable-next-line class-methods-use-this
  read(data) {
    const binInput = buffer.Buffer.from(data, 'base64');
    // 4 first bytes are output length
    // const outputLength = binInput.readUInt32LE(0);
    const outputLength = 65000;
    const utf16Output = buffer.Buffer.alloc(outputLength);
    const compressedInput = binInput.slice(4);

    nrv2eDecompress(compressedInput, utf16Output);

    const textOutput = utf16Output.toString('utf16le');
    return textOutput;
  }
}

export default AztecReader;
