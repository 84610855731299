class ImageProcessor {
  constructor(workerURL) {
    this.worker = new Worker(workerURL);
  }

  processImage(image) {
    return new Promise((resolve, reject) => {
      this.worker.onmessage = (e) => {
        const message = e.data;
        resolve(message.name === 'DECODED' ? message.data : '');
      };

      this.worker.onerror = () => {
        reject();
      };

      this.worker.postMessage({ name: 'DECODE', image });
    });
  }
}

export default ImageProcessor;
