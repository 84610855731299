function readFileAsDataURL(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('loadend', (e) => {
      resolve(e.target.result);
    });
    reader.readAsDataURL(file);
  });
}

function createImage(url) {
  return new Promise((resolve) => {
    const image = new Image();
    image.addEventListener('load', (e) => {
      resolve(e.target);
    });
    image.src = url;
  });
}


function resizeImage(img, maxImageSize) {
  let height;
  let width;
  if (img.width > maxImageSize || img.height > maxImageSize) {
    if (img.width > img.height) {
      width = maxImageSize;
      height = (img.height / img.width) * maxImageSize;
    } else {
      height = maxImageSize;
      width = (img.width / img.height) * maxImageSize;
    }
  } else {
    height = img.height;
    width = img.width;
  }

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

  return ctx.getImageData(0, 0, canvas.width, canvas.height);
}

function successDecode(rawData) {
  const registrationData = this.aztecReader.read(rawData);
  if (this.onDecoded) {
    this.onDecoded(registrationData, rawData);
  }
}

export default class AztecFileUpload {
  constructor(imageProcessor, aztecReader, maxImageSize = 1920) {
    this.imageProcessor = imageProcessor;
    this.aztecReader = aztecReader;
    this.maxImageSize = maxImageSize;
  }

  attachTo(input) {
    input.addEventListener('change', (e) => {
      const file = e.target.files[0];
      e.target.value = null;
      this.decode(file);
    });
  }

  decode(file) {
    if (this.onStarted) this.onStarted();

    readFileAsDataURL(file)
      .then(url => createImage(url))
      .then(img => resizeImage(img, this.maxImageSize))
      .then(resizedImage => this.imageProcessor.processImage(resizedImage))
      .then((rawData) => {
        if (rawData) {
          successDecode.bind(this)(rawData);
        } else if (this.onError) {
          this.onError();
        }
      });
  }
}
