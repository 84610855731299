import 'webrtc-adapter';
import { getVideoDevices, getBackCamera, getNextCamera } from './video-devices';

const idealCameraWidth = 1280;
const idealCameraHeight = 720;

function successAztecEncode(rawData) {
  if (this.onDecoded) {
    const registrationData = this.aztecReader.read(rawData);
    this.onDecoded(registrationData, rawData);
  }
}

function processFrame() {
  this.ctx.drawImage(this.player, 0, 0, this.canvas.width, this.canvas.height);
  const image = this.ctx.getImageData(this.cropRectangle.x,
    this.cropRectangle.y,
    this.cropRectangle.width,
    this.cropRectangle.height);

  if (this.debug) {
    this.previewCtx.putImageData(image, 0, 0);
  }

  this.imageProcessor.processImage(image).then((rawData) => {
    if (!this.scanning) {
      return;
    }

    if (rawData) {
      successAztecEncode.bind(this)(rawData);
    }

    if (this.scanning) {
      requestAnimationFrame(() => processFrame.bind(this)());
    }
  }).catch(() => {
    requestAnimationFrame(() => processFrame.bind(this)());
  });
}

function processFrames() {
  requestAnimationFrame(() => processFrame.bind(this)());
}

function getCameraStream(deviceId) {
  const sizeConstraint = {
    width: { ideal: idealCameraWidth },
    height: { ideal: idealCameraHeight },
  };
  const cameraConstraint = deviceId ? { deviceId: { exact: deviceId } } : { facingMode: { ideal: 'environment' } };
  const videoConstraint = Object.assign({}, cameraConstraint, sizeConstraint);
  const constraints = {
    video: videoConstraint,
  };

  return navigator.mediaDevices.getUserMedia(constraints);
}

export default class AztecScanner {
  static get isSupported() {
    return !!(navigator && navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function');
  }

  constructor(player, hud, imageProcessor, aztecReader, debug) {
    this.player = player;
    this.hud = hud;
    this.imageProcessor = imageProcessor;
    this.aztecReader = aztecReader;
    this.debug = debug;
    this.scanning = false;

    this.player.addEventListener('canplay', () => {
      const { videoWidth, videoHeight } = this.player;

      const cropRectangleWidth = videoWidth * 0.6;
      // noinspection JSSuspiciousNameCombination
      const cropRectangleHeight = cropRectangleWidth;

      this.cropRectangle = {
        x: (videoWidth - cropRectangleWidth) / 2,
        y: (videoHeight - cropRectangleHeight) / 2,
        width: cropRectangleWidth,
        height: cropRectangleHeight,
      };

      this.canvas.width = videoWidth;
      this.canvas.height = videoHeight;
      this.ctx = this.canvas.getContext('2d');

      if (this.debug) {
        this.preview.width = this.cropRectangle.width;
        this.preview.height = this.cropRectangle.height;
        this.previewCtx = this.preview.getContext('2d');
      }

      processFrames.bind(this)();
    });
  }

  static promptForCameraPermission() {
    return navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
      return getBackCamera().then(backCamera => (
        getCameraStream(backCamera ? backCamera.deviceId : null)
      ));
    });
  }

  startScanning(stream) {
    this.scanning = true;

    this.player.srcObject = stream;
    this.player.play();
    this.hud.style.display = 'block';

    this.canvas = document.createElement('canvas');
    this.canvas.style.display = 'none';

    if (this.debug) {
      this.preview = document.createElement('canvas');
      document.body.appendChild(this.preview);
    }
  }

  toggleCamera() {
    if (!this.scanning) {
      return;
    }

    const currentVideoTrack = this.player.srcObject.getVideoTracks()[0];
    const currentDeviceId = currentVideoTrack.getCapabilities().deviceId;

    getNextCamera(currentDeviceId).then((nextCamera) => {
      this.stopScanning();
      getCameraStream(nextCamera.deviceId).then((stream) => {
        this.startScanning(stream);
      });
    });
  }

  static get canToggleCamera() {
    return getVideoDevices().then(devices => devices.length > 0);
  }

  stopScanning() {
    this.scanning = false;
    this.player.pause();
    this.player.srcObject.getTracks()
      .forEach((track) => { track.stop(); });
  }
}
