import isWebView from '../web-view-detection';

export function getVideoDevices() {
  return new Promise((resolve) => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      resolve(devices.filter(d => (/^videoinput$/i).test(d.kind)));
    });
  });
}

export function getBackCamera() {
  return getVideoDevices().then((cameras) => {
    const byLabel = () => cameras.filter(camera => /facing back/.test(camera.label))
      .sort((c1, c2) => c1.label.localeCompare(c2.label))[0];
    // for web view return second camera because labels are empty
    const forWebView = () => ((isWebView() && cameras.length > 1) ? cameras[1] : undefined);

    return byLabel() || forWebView();
  });
}

export function getNextCamera(currentDeviceId) {
  return getVideoDevices().then((cameras) => {
    const prevIndex = cameras.findIndex(camera => camera.deviceId === currentDeviceId);
    const index = prevIndex < cameras.length - 1 ? prevIndex + 1 : 0;
    return cameras[index];
  });
}
